import React from 'react'
import { Link } from 'gatsby'
import {
  Segment,
  Container,
  Image,
  Header,
  Card,
  Label,
} from 'semantic-ui-react'
import Layout from '../../components/layout'
import PageHeader from '../../components/PageHeader'

import './index.css'

import logo_surface from '../../images/surface_white_background_enveloped.svg'
import logo_stream from '../../images/stream_white_background_enveloped.svg'

const SolutionPrimary = () => (
  <Segment as="section" basic>
    <Container>
      <Card.Group centered stackable itemsPerRow={2}>
        <Card link as={Link} to="/solutions/surface/">
          <Image src={logo_surface} alt="Surface" size="medium" />
          <Card.Content>
            <Card.Header>
              An intuitive predictive analytics workbench
            </Card.Header>
            <Card.Meta />
            <Card.Description>
              Easily discover and understand the drivers of a given customer
              behavior, highlight root causes and use them to create
              plug-and-play predictive models to anticipate intentions or detect
              look-alikes.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card link as={Link} to="/solutions/stream/">
          <Image src={logo_stream} alt="Stream" size="medium" />
          <Card.Content>
            <Card.Header>Real-time predictions service</Card.Header>
            <Card.Meta />
            <Card.Description>
              Want to push your predictions into production? Stream integrates
              with Surface to easily and safely move your predictive analytics
              projects into a veritable production workflow. Stream is a highly
              available service with ultra fast response times.
            </Card.Description>
            <Label attached="top">Coming out soon!</Label>
          </Card.Content>
        </Card>
      </Card.Group>
    </Container>
  </Segment>
)

export default () => (
  <Layout>
    <div className="SolutionsPage">
      <PageHeader>
        <Header inverted as="h1">
          Our Solutions
        </Header>
        <p>
          With Mangrove solutions, the user can remain focused on business
          outcomes. Machine Learning power is automatic and transparent, with no
          need to dive into the intricacies of data science.
        </p>
      </PageHeader>
      <SolutionPrimary />
    </div>
  </Layout>
)
